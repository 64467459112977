import * as logClient from "@classdojo/log-client";
import env from "../utils/env";

const keyMatcher = /:[a-zA-Z0-9-_]+/g;

// TODO: Keeping this until we identify from prod what places are using the old approach, once we fix those
// we can remove this logging.
const undefinedParamsFound: { [key: string]: boolean } = {};

const logUndefinedParams = (urlPattern: string, params: Record<string, unknown>) => {
  const url = `urlPattern=[${urlPattern}] params=[${JSON.stringify(params)}]`;

  if (undefinedParamsFound[url]) return;
  undefinedParamsFound[url] = true;

  logClient.logException(
    new Error(`FETCHERS: undefined param values found for the following fetchers:\n` + `${url}`),
    [],
  );
};

const isPathParameter = (urlPattern: string, param: string) => {
  const questionMarkIndex = urlPattern.indexOf("?");

  return questionMarkIndex < 0
    ? urlPattern.indexOf(`:${param}`) >= 0
    : urlPattern.indexOf(`:${param}`) < questionMarkIndex;
};

// From a urlPattern like "/api/school/:schoolId?withPending=:pending"
// and params like { schoolId: 123, pending: false }, build a url
// TODO: break this up into smaller functions
// eslint-disable-next-line complexity
export const buildUrl = (
  { params, urlPattern }: { params?: Record<string, unknown> | null; urlPattern: string },
  allowMissingParam?: boolean,
) => {
  if (params == null || typeof params !== "object" || Array.isArray(params)) {
    throw new Error(`Invalid argument \`params\` [${params}] for urlPattern [${urlPattern}]`);
  }

  const matches = urlPattern.match(keyMatcher);
  if (!matches) {
    return urlPattern;
  }
  const keys = matches.map((s) => s.substring(1));

  let ret = urlPattern;
  for (const key of keys) {
    const isPathParam = isPathParameter(urlPattern, key);
    const value = params[key];
    if ((!allowMissingParam || isPathParam) && !Object.prototype.hasOwnProperty.call(params, key)) {
      throw new Error(`Missing key "${key}" for\nurlPattern: ${urlPattern}\nparams: ${JSON.stringify(params)}`);
    } else if ((!allowMissingParam || isPathParam) && value == undefined) {
      if (env.isDev) {
        throw new Error(
          `Missing value for key "${key}" for\nurlPattern: ${urlPattern}\nparams: ${JSON.stringify(params)}`,
        );
      }

      // @fetcher-revisit: in the end, we will want to throw an error for this case, but, for now,
      // we are keeping compatibility with previous behavior of returning `undefined` so it doesn't
      // do the fetch and it doesn't break production
      logUndefinedParams(urlPattern, params);
      return;
    } else {
      // TODO: In the future we want to encode values here automatically, the only reason why we can't
      // do this right now is due to how we implemented feature switches. Feature switches will work around
      // the fact that we don't have optional query string params by sending only one parameter to the fetcher
      // which is the entire queryString, that way it can manipulate the value of the queryString. But that means
      // that if we run encodeURIComponent on params[key] we will break that one use case.
      if (!env.isProd && value && typeof value === "string") {
        const illegalCharacter = ILLEGAL_CHARACTERS.some((v) => value.includes(v));
        if (illegalCharacter) {
          throw new Error(`
We found a value not encoded in the params for the fetcher.
The following characters need to be encoded: ['${ILLEGAL_CHARACTERS.join("' , '")}'].
Fetchers implementation, sadly, doesn't encode urls automatically. There are some
limitations we need to work around in order to do so. Meantime please encode the params manually
when calling the fetcher.
** Example: useMyFetcher({ from: encodeURIComponent("10:00+01") })
`);
        }
      }

      if (!allowMissingParam || value || value === "") {
        ret = ret.replace(`:${key}`, `${value}`);
      } else {
        ret = ret.replace(new RegExp(`${key}=:${key}&?`), "");
      }
    }
  }

  // Get rid of '?' or '&' char if one of those is the last character
  return ret.endsWith("?") || ret.endsWith("&") ? ret.slice(0, -1) : ret;
};

const ILLEGAL_CHARACTERS = ["+", " "];
