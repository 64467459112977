import { onlineManager } from "@tanstack/react-query";

import { makeCollectionQuery } from "./collectionQuery";
import { makeMemberQuery } from "./memberQuery";
import { makeMutation, makeApiMutation, type OperationParams, type OperationResult } from "./mutation";
import { useReactiveLocalStorage } from "./reactiveLocalStorage";

export { useMutation, useQuery, useInfiniteQuery } from "@tanstack/react-query";
export type { UseMutationOptions } from "@tanstack/react-query";
onlineManager.setOnline(true);

export { makeMemberQuery, makeCollectionQuery, makeMutation, makeApiMutation, useReactiveLocalStorage };

export { NOOP, WAITING_FOR_DEPENDENCIES, CUSTOM_HEADERS } from "./constants";

export type { OperationParams, OperationResult };

export { queryClient } from "./queryClient";
export { makeQueryKey } from "./queryKey";
