/** global process */
import { APIResponseError } from "./responseHandlers";
import { getAgent } from "./local-superagent";
import { start } from "./waitForAsyncTasks";
import request, { Response, ResponseError } from "superagent";
import map from "lodash/map";
import memoize from "lodash/memoize";
import { ok } from "assert";

/* eslint-disable @typescript-eslint/no-namespace, no-var */
declare global {
  namespace NodeJS {
    interface Global {
      appVersion?: string;
      useCanary?: boolean;
      usePrecanary?: boolean;
      usePreCanary?: boolean;
      useEKS?: boolean;
      usePerformance?: boolean;
      useStaging?: boolean;
      useClassToken?: boolean;
      forcePrince?: boolean;
    }
  }
  namespace globalThis {
    var appVersion: undefined | string;
    var useCanary: undefined | boolean;
    var usePrecanary: undefined | boolean;
    var usePreCanary: undefined | boolean;
    var useEKS: undefined | boolean;
    var usePerformance: undefined | boolean;
    var useStaging: undefined | boolean;
    var useClassToken: undefined | boolean;
    var forcePrince: undefined | boolean;
  }
}
/* eslint-enable @typescript-eslint/no-namespace */

export type Method = "GET" | "PUT" | "POST" | "PATCH" | "DELETE" | "HEAD";

// options is in the shape of HTML5 fetch() options
type FetchOptions = {
  method?: Method;
  path: string;
  query?: { [k: string]: string | boolean };
  body?: string | Buffer;
  headers?: { [k: string]: string };
};

let preloadedJsonHrefs: string[];
function findPreloadedJsonHrefs(): string[] {
  if (preloadedJsonHrefs) {
    return preloadedJsonHrefs;
  }
  if (!global.document) {
    return [];
  }
  const links = global.document.querySelectorAll('link[rel="preload"][type="application/json"]');
  return (preloadedJsonHrefs = map(links, (link) => {
    const href = link.getAttribute("href");
    ok(href, `link[rel="preload"] with no href`);
    return href;
  }));
}

function isPreloaded(path: string): boolean {
  const hrefs = findPreloadedJsonHrefs();
  return hrefs.some((href) => href === path);
}

const lower = memoize((s: string) => s.toLowerCase());

type Headers = Record<string, string>;

function getFetcher({ method = "GET", path, query = {}, headers = {} }: FetchOptions): request.SuperAgentRequest {
  // Ensure headers aren't modified for any json resources we are preloading
  const preloaded = isPreloaded(path) && method === "GET";
  const defaultHeaders = preloaded ? htmlClientHeaders() : jsClientHeaders();

  const methodName = lower(method) as "get" | "put" | "post" | "patch" | "delete";
  return getAgent()
    [methodName](path)
    .query(query)
    .set({
      ...headers,
      ...defaultHeaders,
    });
}

function htmlClientHeaders(): Headers {
  if (!global.location) return {};
  return { origin: global.location.origin };
}

function jsClientHeaders(): Headers {
  const headers: Headers = {
    "x-client-identifier": "Web",
    "x-sign-attachment-urls": "true",
  };
  if (global.appVersion) {
    headers["x-client-version"] = global.appVersion;
  }
  return headers;
}

export function fetchWithoutCredentials(options: FetchOptions): Promise<Response> {
  return getFetcher(options).send(options.body);
}

export function wrappedFetch(options: FetchOptions): Promise<Response> {
  return getFetcher(options).withCredentials().send(options.body);
}

export type CallApiParams = {
  method?: Method;
  path: string;
  query?: { [k: string]: string | boolean };
  body?: Record<string, unknown> | Record<string, unknown>[];
  headers?: { [k: string]: string };
};

export default function callApi({ method, path, body = undefined, query = {}, headers = {} }: CallApiParams) {
  if (Config.bundler === "jest") {
    if (path[0] === "/") {
      path = `${Config.apiEndpoint ?? ""}${path}`;
    }
  }

  // must be present for API to use class token instead of teach cookie
  // for auth for routes like storyPost, class students, etc.

  if (global.useCanary) {
    query.useCanary = "true";
  } else if (global.usePrecanary || global.usePreCanary) {
    query.usePrecanary = "true";
  } else if (global.useEKS) {
    query.useEKS = "true";
  } else if (global.usePerformance) {
    query.usePerformance = "true";
  } else if (global.useStaging) {
    query.useStaging = "true";
  }

  if (global.useClassToken) {
    query.useClassToken = "true";
  }

  // Remove undefined entries from query object, superagent 5 passes them through as string undefined
  Object.keys(query).forEach((key) => query[key] === undefined && delete query[key]);

  const params: Parameters<typeof wrappedFetch>[0] = { headers, path, query };

  if (method) params.method = method;
  if (body) {
    params.body = JSON.stringify(body);
    params.headers = Object.assign(headers, {
      "Content-Type": "application/json",
    });
  }

  const complete = start();
  return wrappedFetch(params).then(
    (res) => {
      complete();
      return res;
    },
    (err: ResponseError) => {
      complete();

      if (err.status && err.response) {
        // convert to our own APIResponseError
        const apiResponseError = new APIResponseError(err.response);

        // If we get a read-only session forbidden error, set isExpected true so that
        // the mutation useErrorBoundary supresses the error.
        if (err.response.body?.error?.detail?.isReadOnlySessionError) {
          apiResponseError.isExpected = true;
        }

        throw apiResponseError;
      }

      throw err;
    },
  );
}

//
// Use this type when you are returning the entire result from callApi.
// i.e. when you are typing an already existent operation that is already doing `return callApi`
//
// Is not ideal since callApi is returning the superagent response, whihc lacks proper typing.
// If you are writing any new operation, do not return the whole superagent response, instead
// grab the response or parts of the response you want and return that (and type it in the firm
// of the operation).
export type CallApiDefaultResponse = Response;
